import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Title = styled.h3`
  ${tw`font-semibold mb-4`};
`;

export const Image = styled.figure`
  ${tw`w-full rounded-lg overflow-hidden mt-4 mb-10`};
`;

export const Links = styled.div`
  ${tw`w-full flex justify-between mt-10`};
`;

export const ProductPageWrapper = styled.div`
  ${tw`m-auto pt-4 sm:pt-10`};
  display: none;
  @media screen and (min-width: 992px) {
    display: inherit;
  }

  .container {
    .grid {
      .individual-product{
        padding: 1.5rem;
        background: #FFF;
        grid-column: span 9 / span 9;
        @media screen and (max-width: 1280px) {
          grid-column: span 12 / span 12;
        }
      }

      .minicart{
        ${tw`hidden xl:block`};
        grid-column: span 3 / span 3;
        @media screen and (max-width: 1280px) {
          ${tw`hidden`}
        }
        .stickyCart{
          ${tw`sticky pt-4`}
          top: 0;
        }
      }

    }
  }
`;

export const ProductPageMobileWrapper = styled.div`
  @media screen and (min-width: 991px) {
    display: none;
  }
`;
