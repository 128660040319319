import React, { useEffect, useState } from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import ProductView from '../../components/ui/Modal/ProductView';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import { selectPostcode$ } from '../../state/checkout/checkout.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as Styled from './styles';
import Minicart from '../../components/Minicart';
import Tablet from '../../components/Header/MainNavi/TabsMenuMobile';
import ProductMobileView from '../../components/ui/Modal/Mobile/product';
import PostCodeModal from '../../components/PostcodeModal';
import Modal from 'react-modal';
import GoBackLink from "../../components/ui/GoBackLink";
import { graphql, useStaticQuery } from "gatsby";

const ProductPage = ({ pageContext }) => {
  const { language } = React.useContext(I18nextContext);
  const locale = language;
  const gql = useStaticQuery(graphql`
    query {
      strapiNavigationMenu_en: strapiNavigationMenu(locale: { eq: "en" }) {
        go_back
      }
      strapiNavigationMenu_zh: strapiNavigationMenu(locale: { eq: "zh" }) {
        go_back
      }
    }
  `);
  const navTranslations = gql[`strapiNavigationMenu_${locale}`];
  const [unMount$] = useState(new Subject());
  const [postCode, setPostcode] = useState(null);
  const [showPostcodeModal, setShowPostcodeModal] = useState(false);
  const productToShow = pageContext.node;

  const localizedProductName = (product) => {
    const name = locale === 'en' ? product.name : product.name_cn;

    if (!!product.volume) {
      let volume;
      const arr = product.volume.split('.');
      if (arr[1] && arr[1] === '00') {
        volume = arr[0];
      } else {
        volume = product.volume;
      }
      return `${ name } ${ volume || '' } ${ product.volume_unit || '' }`;
    }

    return name;
  };

  useEffect(() => {
    selectPostcode$.pipe(takeUntil(unMount$)).subscribe((postcode) => {
      setPostcode(postcode);
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  return (
    <Layout page="individual-product-page" locale={ locale }>
      <SEO title={ pageContext.node.name }/>
      <Styled.ProductPageWrapper>
        <div className="container mx-auto">
          <GoBackLink title={navTranslations.go_back} link="/shop"/>
          <br/>
          <div className="grid grid-cols-12 gap-4">
            <div className={ 'individual-product' }>
              <ProductView
                id={ productToShow.id }
                img={
                  !productToShow.inventory
                    ? productToShow.img_path
                    : productToShow.base_variation.img_path
                }
                name={ localizedProductName(productToShow) }
                description={
                  locale === 'en' ? productToShow.description : productToShow.description_cn
                }
                price={
                  !productToShow.inventory
                    ? productToShow.online_price_sum
                    : productToShow.base_variation.online_price
                }
                item={ productToShow }
                hasPostcode={ !!postCode }
                noPostcodeCallback={ () => {
                } }
                addedCallback={ () => {
                } }
                locale={ locale }
                closable={ false }
                isStaticPage={ true }
              />
            </div>
            <div className="minicart">
              <div className="stickyCart">
                <Minicart locale={ locale }/>
              </div>
            </div>
          </div>
        </div>
      </Styled.ProductPageWrapper>
      <Styled.ProductPageMobileWrapper>
        <div className="px-6">
          <GoBackLink title={navTranslations.go_back} link="/shop"/>
        </div>
        <ProductMobileView
          id={ productToShow.id }
          img={
            !productToShow.inventory
              ? productToShow.img_path
              : productToShow.base_variation.img_path
          }
          name={ localizedProductName(productToShow) }
          description={ locale === 'en' ? productToShow.description : productToShow.description_cn }
          price={
            !productToShow.inventory
              ? productToShow.online_price_sum
              : productToShow.base_variation.online_price
          }
          item={ productToShow }
          hasPostcode={ !!postCode }
          noPostcodeCallback={ () => {
            setShowPostcodeModal(true);
          } }
          addedCallback={ () => {
          } }
          locale={ locale }
          isStaticPage={ true }
        />
        <div className="minicart p-6">
          <div className="stickyCart">
            <Minicart locale={ locale }/>
          </div>
        </div>
      </Styled.ProductPageMobileWrapper>
      <div className="tabletShopIcon">
        <Tablet locale={ locale }/>
      </div>
      <Modal
        isOpen={ showPostcodeModal }
        onRequestClose={ () => setShowPostcodeModal(false) }
        contentLabel="My dialog"
        id="postCodeModal"
      >
        <PostCodeModal
          type="1"
          suggestionSelectedCustomCallback={ () => setShowPostcodeModal(false) }
        />
      </Modal>
    </Layout>
  );
};

export default ProductPage;
